export default class ExpandableElement extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this._addEventListener();
	}

	disconnectedCallback() {
		this._removeEventListener();
	}

	_addEventListener() {
		this.addEventListener('click', this._onToggleState);
	}

	_removeEventListener() {
		this.removeEventListener('click', this._onToggleState);
	}

	/**
	 * toggle the open of the expandable
	 * @returns {void}
	 */
	_onToggleState() {
		this.isOpen = !this.isOpen;
	}

	/**
	 * @type {boolean} true (open) / false (closed)
	 */
	get isOpen() {
		return this.getAttribute('is-open') === 'true';
	}

	/**
	 * @param {String} val true for open / false for closed
	 */
	set isOpen(val) {
		this.setAttribute('is-open', val);
	}
}

if (window.customElements.get('audi-expandable') === undefined) {
	window.customElements.define('audi-expandable', ExpandableElement);
}
